import Box from "@mui/material/Box";
import ConfigTariffEdit from "./ConfigTariffEdit";
import ConfigTariffAdding from "./ConfigTariffAdding";

const ConfigTariff = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: "50px" }}>
      <ConfigTariffEdit />
      <ConfigTariffAdding />
    </Box>
  );
};

export default ConfigTariff;
