import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useAuthContext } from "../../Context/AuthContext";

// Walidacja schematu
const schema = yup.object().shape({
  eventId: yup.string().required("Wybierz wydarzenie do usunięcia"),
});

const DeleteEvent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const { serverUrl } = useAuthContext();

  useEffect(() => {
    axios
      .get(`${serverUrl}/api/eventAll`)
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error("Błąd podczas pobierania wydarzeń:", error);
      });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    const isConfirmed = window.confirm(
      `Czy na pewno chcesz usunąć wydarzenie?`
    );

    if (isConfirmed) {
      axios
        .delete(`${serverUrl}/api/eventDelete/${data.eventId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          alert("Wydarzenie zostało pomyślnie usunięte.");
          setEvents(events.filter((event) => event.id !== data.eventId));
        })
        .catch((error) => {
          alert("Błąd podczas usuwania wydarzenia.");
          console.error(error);
        });
    }
  };

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" noValidate>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}>
        <CardContent sx={{ width: "50%" }}>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="event-select-label">Wybierz wydarzenie</InputLabel>
            <Select
              labelId="event-select-label"
              id="event-select"
              value={selectedEvent}
              label="Wybierz wydarzenie"
              {...register("eventId")}
              onChange={(e) => setSelectedEvent(e.target.value)}
              error={!!errors.eventId}>
              {events.map((event) => (
                <MenuItem key={event.id} value={event.id}>
                  {event.title}
                </MenuItem>
              ))}
            </Select>
            {errors.eventId && (
              <p style={{ color: "red" }}>{errors.eventId.message}</p>
            )}
          </FormControl>
        </CardContent>
        <CardActions>
          <Button type="submit" size="small">
            Usuń
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default DeleteEvent;
