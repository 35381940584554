import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import axios from "axios";

import { useAuthContext } from "../Context/AuthContext";

const Login = () => {
  const { setIsLogged, serverUrl } = useAuthContext();
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const onSubmit = (data) => {
    axios
      .post(`${serverUrl}/auth/login`, data)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        setIsLogged(true);
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage(error.response.data.message);
          setOpenSnackbar(true);
        } else {
          console.error("Wystąpił nieoczekiwany problem:", error.message);
          setErrorMessage("Wystąpił nieoczekiwany błąd.");
          setOpenSnackbar(true);
        }
      });
  };

  const schema = yup.object().shape({
    login: yup.string().required("Login is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])/,
        "Hasło musi zawierać dużą literę, cyfrę i znak specjalny"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      login: "",
      password: "",
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}>
      <Card sx={{ width: 400 }}>
        <CardContent>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <TextField
              label="Login"
              variant="standard"
              id="login"
              {...register("login")}
              fullWidth
              margin="normal"
              error={!!errors.login}
              helperText={errors.login?.message}
            />
            <TextField
              id="password"
              {...register("password")}
              label="Hasło"
              variant="standard"
              type="password"
              fullWidth
              margin="normal"
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}>
              <Button size="small" type="submit">
                Zaloguj się
              </Button>
            </CardActions>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
