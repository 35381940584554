import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { useForm } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import FormHelperText from "@mui/material/FormHelperText";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { number, object, boolean, string, array } from "yup";
import axios from "axios";
import { useAuthContext } from "../../Context/AuthContext";

const schema = object().shape({
  price: number()
    .when("category", {
      is: false,
      then: () =>
        number()
          .min(1, "Cena musi być większa od 0")
          .required("Cena jest wymagana"),
      otherwise: () => number().nullable(),
    })
    .typeError("Cena musi być liczbą"),

  position: number().nullable().typeError("Pozycja musi być liczbą lub null"),

  category: boolean().required("Kategoria jest wymagana"),

  aditionalElements: array().when("category", {
    is: false,
    then: () => array().nullable(),
    otherwise: () =>
      array().of(
        object().shape({
          aditionalName: string().required("Nazwa jest wymagana"),
          aditionalPrice: number()
            .min(1, "Cena musi być większa od 0")
            .required("Cena jest wymagana")
            .typeError("Cena musi być liczbą"),
        })
      ),
  }),
});

const ConfigTariffAdding = () => {
  const { serverUrl } = useAuthContext();
  const [aditionalElements, setAditionalElements] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleAddElement = () => {
    setAditionalElements([
      ...aditionalElements,
      {
        id: aditionalElements.length + 1,
        aditionalName: "",
        aditionalPrice: "",
      },
    ]);
  };

  const handleChange = (index, field, value) => {
    const updatedElements = [...aditionalElements];
    updatedElements[index][field] = value;
    setAditionalElements(updatedElements);
    setValue(`aditionalElements.${index}.${field}`, value);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      price: 0,
      position: null,
      category: false,
      aditionalElements: [{ aditionalName: "", aditionalPrice: 0 }],
    },
    resolver: yupResolver(schema),
  });

  const isCategorySelected = watch("category");

  useEffect(() => {
    if (!isCategorySelected) {
      setAditionalElements([]);
    } else if (isCategorySelected) {
      setValue("price", null);
    }
  }, [isCategorySelected, watch, setValue]);

  const onSubmit = (data) => {
    const newTariff = {
      ...data,
      aditionalElements,
    };

    axios
      .post(`${serverUrl}/api/TariffAdd`, newTariff, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Error adding tariff:", error);
        setOpenSnackbar(true);
        setErrorMessage("Wystąpił błąd");
      });
  };

  return (
    <Box>
      <Box onSubmit={handleSubmit(onSubmit)} component="form" noValidate>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <TextField
              fullWidth
              label="Nazwa"
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <TextField
              fullWidth
              label="Cena"
              type="number"
              {...register("price")}
              disabled={isCategorySelected}
              error={!!errors.price}
              helperText={errors.price?.message}
            />
            <TextField
              fullWidth
              label="Pozycja w Wyświetlaniu"
              type="number"
              {...register("position")}
              error={!!errors.position}
              helperText={errors.position?.message}
            />
            <FormControl error={!!errors.category}>
              <FormControlLabel
                control={<Checkbox {...register("category")} color="primary" />}
                label="Kategoria"
              />
              {errors.category && (
                <FormHelperText>{errors.category.message}</FormHelperText>
              )}
            </FormControl>
            <Box>
              {aditionalElements.map((element, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    marginBottom: 2,
                  }}>
                  <TextField
                    fullWidth
                    {...register(`aditionalElements.${index}.aditionalName`)}
                    error={!!errors.aditionalElements?.[index]?.aditionalName}
                    helperText={
                      errors.aditionalElements?.[index]?.aditionalName?.message
                    }
                    label="Nazwa"
                    value={element.aditionalName}
                    onChange={(e) =>
                      handleChange(index, "aditionalName", e.target.value)
                    }
                  />
                  <TextField
                    fullWidth
                    {...register(`aditionalElements.${index}.aditionalPrice`)}
                    error={!!errors.aditionalElements?.[index]?.aditionalPrice}
                    helperText={
                      errors.aditionalElements?.[index]?.aditionalPrice?.message
                    }
                    label="Cena"
                    type="number"
                    value={element.aditionalPrice}
                    onChange={(e) =>
                      handleChange(index, "aditionalPrice", e.target.value)
                    }
                  />
                </Box>
              ))}

              <Button
                variant="contained"
                onClick={handleAddElement}
                disabled={!isCategorySelected}>
                Dodaj element
              </Button>
            </Box>
          </CardContent>

          <CardActions>
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="primary">
              Dodaj taryfę
            </Button>
          </CardActions>
        </Card>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ConfigTariffAdding;
