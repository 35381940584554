import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuthContext } from "../Context/AuthContext";
import Login from "./Login";
import axios from "axios";

const ProtectedRoute = () => {
  const { isLogged, setIsLogged, serverUrl } = useAuthContext();
  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const response = await axios.post(`${serverUrl}/auth/verify-token`, {
            token,
          });
          if (response.data.valid) {
            setIsLogged(true);
          } else {
            setIsLogged(false);
            localStorage.removeItem("token");
          }
        } catch (error) {
          setIsLogged(false);
        }
      } else {
        setIsLogged(false);
      }
    };

    verifyToken();
  }, [setIsLogged, serverUrl]);

  if (!isLogged) {
    return <Login />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
