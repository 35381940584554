import { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import { useAuthContext } from "../../Context/AuthContext";

const schema = yup.object().shape({
  name: yup.string().required("Nazwa produktu jest wymagana"),
  description: yup.string().required("Opis produktu jest wymagany"),
  price: yup
    .number()
    .required("Cena produktu jest wymagana")
    .min(1, "Cena musi być większa lub równa 1"),
  promotion: yup.boolean().default(false),
  promotionPrice: yup
    .number()
    .nullable()
    .test(
      "isPromotionValid",
      "Błąd walidacji promotionPrice",
      function (value) {
        const { promotion } = this.parent;
        if (promotion && (!value || value < 1)) {
          return this.createError({
            message: "Cena promocyjna jest wymagana i musi być większa niż 0",
          });
        }
        return true;
      }
    ),
  image: yup
    .mixed()
    .required("Zdjęcie jest wymagane")
    .test(
      "fileType",
      "Dozwolone są tylko pliki w formacie .jpeg, .jpg, .png",
      (value) => {
        return (
          value &&
          value.type &&
          ["image/jpeg", "image/jpg", "image/png"].includes(value.type)
        );
      }
    )
    .test("fileSize", "Rozmiar pliku nie może przekraczać 0.60 MB", (value) => {
      return value && value.size <= 65000;
    }),
});

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const ConfigAddShop = () => {
  const { serverUrl } = useAuthContext();
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setValue("image", file, { shouldValidate: true });
    }
  };

  const onSubmit = async (data) => {
    const file = data.image;
    const base64 = await convertToBase64(file);

    axios
      .post(
        `${serverUrl}/api/shop-upload`,
        { ...data, image: base64 },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        reset();
        setImagePreview(null);
      })
      .catch((err) => {
        console.error(
          "Błąd podczas przesyłania pliku:",
          err.response?.data || err
        );
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      name: "",
      description: "",
      price: 0,
      promotion: false,
      promotionPrice: null,
      image: null,
    },
  });
  const promotion = watch("promotion");

  useEffect(() => {
    if (!promotion) {
      setValue("promotionPrice", null, { shouldValidate: true });
    }
  }, [promotion, setValue]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "40%",
        }}>
        <TextField
          label="Nazwa produktu"
          variant="standard"
          {...register("name")}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          label="opis"
          variant="standard"
          {...register("description")}
          error={!!errors.description}
          helperText={errors.description?.message}
        />
        <TextField
          label="cena"
          variant="standard"
          {...register("price")}
          error={!!errors.price}
          helperText={errors.price?.message}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormControl error={!!errors.promotion}>
            <FormControlLabel
              control={<Checkbox {...register("promotion")} color="primary" />}
              label="Promocja"
            />
            {errors.promotion && (
              <FormHelperText>{errors.promotion.message}</FormHelperText>
            )}
          </FormControl>
          <TextField
            label="cena promocyjna"
            variant="standard"
            {...register("promotionPrice")}
            disabled={!watch("promotion")}
            error={!!errors.promotionPrice}
            helperText={errors.promotionPrice?.message}
          />
        </Box>

        <Button variant="contained" component="label">
          Wybierz plik
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={(e) => {
              handleImageChange(e);
              const file = e.target.files[0];
              if (file) {
                setValue("image", file, { shouldValidate: true });
              }
            }}
          />
        </Button>
        {imagePreview && (
          <Box sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}>
            <img
              src={imagePreview}
              alt="Podgląd"
              style={{ maxWidth: "100%", maxHeight: "200px" }}
            />
          </Box>
        )}
        {errors.image && (
          <Typography variant="body2" color="error">
            {errors.image.message}
          </Typography>
        )}

        <Button variant="contained" type="submit">
          Wyślij
        </Button>
      </Box>
    </Box>
  );
};

export default ConfigAddShop;
