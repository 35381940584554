import { useNavigate } from "react-router-dom";
import "../../../styles/layout_shop.css";
import { Button, Box } from "@mui/material";

function LayoutShop() {
  const navigate = useNavigate();
  return (
    <div className="layoutShop">
      <div className="title">
        <p>Sklepik</p>
      </div>
      <div className="content">
        <p>
          Zapraszamy do sklepiku przy recepcji. Znajdziesz tam sporo
          asortymentu, który wspomoże efekty twojego treningu.
        </p>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="outlined"
          onClick={() => navigate("/sklep")}
          sx={{
            marginTop: "50px",
            color: "black",
            borderColor: "black",
            fontSize: "1.1rem",
          }}>
          Nasza oferta
        </Button>
      </Box>
    </div>
  );
}

export default LayoutShop;
