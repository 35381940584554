import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(false);

  // const serverUrl = "http://localhost:5000";
  const serverUrl = "https://power-on-gym-7kdm.vercel.app";

  return (
    <AuthContext.Provider value={{ isLogged, setIsLogged, serverUrl }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};
