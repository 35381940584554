import "../../../styles/layout_Info.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

function LayoutInfo() {
  const Navigate = useNavigate();

  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const { ref: ref4, inView: inView4 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const { ref: ref5, inView: inView5 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const { ref: ref6, inView: inView6 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div className="layout-info">
      <div className="cards-cont">
        <motion.div
          className="card"
          ref={ref1}
          initial={{ opacity: 0, y: 300, rotate: 10 }}
          animate={inView1 ? { opacity: 1, y: 0, rotate: 0 } : {}}
          transition={{ duration: 0.6, ease: "easeOut" }}>
          <div className="card-img ">
            <img
              src="/images/Offert.jpg"
              className="cover-image Hover"
              alt="cos"
              onClick={() =>
                Navigate("/o-nas", { state: { targetId: "treners" } })
              }
            />
          </div>
          <div
            className="card_wraper Hover"
            onClick={() =>
              Navigate("/o-nas", { state: { targetId: "treners" } })
            }>
            <p
              className="title Hover"
              onClick={() =>
                Navigate("/o-nas", { state: { targetId: "treners" } })
              }>
              Trening Personalny
            </p>
            <p
              className="content Hover"
              onClick={() =>
                Navigate("/o-nas", { state: { targetId: "treners" } })
              }>
              W naszym programie treningu personalnego, który jest stworzony z
              myślą o Tobie! Nasze sesje treningowe są dostosowane do Twoich
              indywidualnych potrzeb, celów oraz poziomu sprawności fizycznej.
              Niezależnie od tego, czy chcesz schudnąć, zbudować masę mięśniową,
              poprawić siłę, czy zwiększyć wydolność, nasz zespół
              wykwalifikowanych trenerów osobistych jest tutaj, aby Ci pomóc.
            </p>
            <p
              className="btn"
              onClick={() =>
                Navigate("/o-nas", { state: { targetId: "treners" } })
              }>
              Zobacz więcej
            </p>
          </div>
        </motion.div>
        <motion.div
          className="card rev"
          ref={ref2}
          initial={{ opacity: 0, y: 300, rotate: -10 }}
          animate={inView2 ? { opacity: 1, y: 0, rotate: 0 } : {}}
          transition={{ duration: 0.6, ease: "easeOut" }}>
          <div className="card-img ">
            <img
              src="/images/card_fitness.jpg"
              className="cover-image Hover"
              alt="cos"
              onClick={() => Navigate("/zajecia-fitness")}
            />
          </div>
          <div
            className="card_wraper Hover"
            onClick={() => Navigate("/zajecia-fitness")}>
            <p
              className="title Hover"
              onClick={() => Navigate("/zajecia-fitness")}>
              Zajęcia fitness
            </p>
            <p
              className="content Hover"
              onClick={() => Navigate("/zajecia-fitness")}>
              W naszej sekcji zajęć fitness, gdzie różnorodność i energia
              spotykają się, aby dostarczyć Ci motywacji i radości z aktywności
              fizycznej. Oferujemy szeroki wachlarz zajęć, które są dostosowane
              do różnych poziomów sprawności i preferencji, zapewniając, że
              każdy znajdzie coś dla siebie.
            </p>
            <p className="btn" onClick={() => Navigate("/zajecia-fitness")}>
              Zobacz więcej
            </p>
          </div>
        </motion.div>
        <motion.div
          className="card"
          ref={ref3}
          initial={{ opacity: 0, y: 300, rotate: 10 }}
          animate={inView3 ? { opacity: 1, y: 0, rotate: 0 } : {}}
          transition={{ duration: 0.6, ease: "easeOut" }}>
          <div className="card-img">
            <img
              src="/images/card_food.jpg"
              className="cover-image"
              alt="cos"
            />
          </div>
          <div className="card_wraper">
            <p className="title">Plany treningowe i plany żywieniowe</p>
            <p className="content">
              Oferujemy indywidualnie dopasowane plany żywieniowe i treningowe,
              które uwzględniają Twoje potrzeby, preferencje smakowe oraz cele
              zdrowotne. Niezależnie od tego, czy chcesz schudnąć, zbudować masę
              mięśniową, czy poprawić swoje nawyki żywieniowe, nasze programy są
              zaprojektowane tak, aby były smaczne, zdrowe i łatwe do wdrożenia
              w codziennym życiu. Dzięki różnorodnym treningom – od siłowych po
              cardio i HIIT ( intensywne, siłowe ) – oraz zbilansowanej diecie,
              pomożemy Ci osiągnąć zamierzone rezultaty w sposób efektywny i
              bezpieczny.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="card rev"
          ref={ref4}
          initial={{ opacity: 0, y: 300, rotate: -10 }}
          animate={inView4 ? { opacity: 1, y: 0, rotate: 0 } : {}}
          transition={{ duration: 0.6, ease: "easeOut" }}>
          <div className="card-img">
            <img
              src="/images/card_massage.jpg"
              className="cover-image Hover"
              alt="cos"
              onClick={() => Navigate("/masaż/")}
            />
          </div>
          <div
            className="card_wraper Hover"
            onClick={() => Navigate("/masaż/")}>
            <p className="title Hover" onClick={() => Navigate("/masaż/")}>
              Masaż
            </p>
            <p className="content Hover" onClick={() => Navigate("/masaż/")}>
              W naszej sekcji zajęć fitness, gdzie różnorodność i energia
              spotykają się, aby dostarczyć Ci motywacji i radości z aktywności
              fizycznej. Oferujemy szeroki wachlarz zajęć, które są dostosowane
              do różnych poziomów sprawności i preferencji, zapewniając, że
              każdy znajdzie coś dla siebie.
            </p>
            <p onClick={() => Navigate("/masaż/")} className="btn">
              Zobacz więcej
            </p>
          </div>
        </motion.div>
        <motion.div
          className="card "
          ref={ref5}
          initial={{ opacity: 0, y: 300, rotate: -10 }}
          animate={inView5 ? { opacity: 1, y: 0, rotate: 0 } : {}}
          transition={{ duration: 0.6, ease: "easeOut" }}>
          <div className="card-img" Hover>
            <img
              src="/images/card_birthdays.jpg"
              className="cover-image Hover"
              alt="cos"
              onClick={() => Navigate("/oferta-urodzinowa")}
            />
          </div>
          <div
            className="card_wraper Hover"
            onClick={() => Navigate("/oferta-urodzinowa")}>
            <p
              className="title Hover"
              onClick={() => Navigate("/oferta-urodzinowa")}>
              Aktywne urodziny
            </p>
            <p
              className="content Hover"
              onClick={() => Navigate("/oferta-urodzinowa")}>
              Aktywne urodziny dla dzieci na siłowni to doskonała okazja, aby
              połączyć zabawę z aktywnością fizyczną w radosnej atmosferze. Tego
              typu impreza jest idealna dla dzieci, które lubią ruch i sport, a
              także dla tych, które chcą spróbować nowych aktywności w
              towarzystwie rówieśników
            </p>
            <p onClick={() => Navigate("/oferta-urodzinowa")} className="btn">
              Zobacz więcej
            </p>
          </div>
        </motion.div>
        <motion.div
          className="card rev"
          ref={ref6}
          initial={{ opacity: 0, y: 300, rotate: 10 }}
          animate={inView6 ? { opacity: 1, y: 0, rotate: 0 } : {}}
          transition={{ duration: 0.6, ease: "easeOut" }}>
          <div className="card-img">
            <img src="/images/Offert.jpg" className="cover-image" alt="cos" />
          </div>
          <div className="card_wraper">
            <p className="title">Oferta dla firm</p>
            <p className="content">
              Zadbaj o zdrowie i kondycję swoich pracowników! Nasza siłownia
              oferuje specjalnie dostosowane programy i pakiety dla firm, które
              chcą inwestować w dobrostan swojego zespołu. Dzięki współpracy z
              nami, możesz zapewnić swoim pracownikom dostęp do nowoczesnych
              sprzętów, profesjonalnych trenerów oraz różnorodnych zajęć
              grupowych. Wspieramy zdrowy styl życia, który przekłada się na
              zwiększenie wydajności i morale w pracy. Sprawdź naszą ofertę i
              dołącz do grona firm, które stawiają na zdrowie i aktywność swoich
              pracowników!
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default LayoutInfo;
