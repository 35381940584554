import "../../../styles/schedule.css";

const Schedule = (props) => {
  const { handleDelete, hours, data, locationPath, daysOfWeek } = props;

  const sortedHours = hours
    .map((hour) => {
      const [hourPart, minutePart] = hour.split(":").map(Number);
      return {
        original: hour,
        dateObj: new Date(0, 0, 0, hourPart, minutePart),
      };
    })
    .sort((a, b) => a.dateObj - b.dateObj)
    .map((item) => item.original);

  const renderClassesForTime = (day, time) => {
    if (!data) {
      return null;
    }
    const classesAtTime = data.filter(
      (cls) => cls.date === day && cls.time === time
    );
    return classesAtTime.length > 0 ? (
      <div className="classContainer">
        {classesAtTime.map((cls) => (
          <div
            key={cls._id}
            className="classInfo"
            onClick={
              locationPath === "/configpanel"
                ? () => handleDelete(cls._id)
                : null
            }>
            <strong>{cls.title}</strong> <br />
            <span>Trener: {cls.trener}</span>
          </div>
        ))}
      </div>
    ) : null;
  };

  return (
    <div>
      <div className="schedule">
        <div className="table">
          <div className="row">
            <div className="cellHeader top"></div>
            {daysOfWeek.map((day, index) => (
              <div key={index} className="cellHeader">
                {day}
              </div>
            ))}
          </div>

          {sortedHours.map((hour, hourIndex) => (
            <div key={hourIndex} className="row">
              <div className="cellHr">{hour}</div>
              {daysOfWeek.map((day, dayIndex) => (
                <div key={dayIndex} className="cell">
                  {renderClassesForTime(day, hour)}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
