import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useAuthContext } from "../Context/AuthContext";
import axios from "axios";
import "../../styles/tariff.css";

export const TariffElement = () => {
  const [TariffData, setTariffData] = useState([]);
  const [load, setLoad] = useState(true);
  const { serverUrl } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/TariffAll`);
        setTariffData(response.data);
        setLoad(false);
      } catch (error) {
        console.error("Error fetching tariff data:", error);
      }
    };
    fetchData();
  }, [serverUrl, setTariffData]);

  const Teriffcolumn = () => {
    return TariffData.sort((a, b) => a.position - b.position).map((element) => (
      <Box
        key={element._id}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: { xs: "80%", md: "70%" },
            justifyContent: "space-between",
            marginTop: "40px",

            borderBottom: "1px solid black",
          }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: "1.5rem", md: "2.5rem" },
              fontWeight: "300",
            }}>
            {element.name}
          </Typography>
          {element.price && (
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "1.5rem", md: "2.5rem" },
                fontWeight: "300",
                width: { xs: "40%", md: "30%" },
                textAlign: "right",
              }}>
              {element.price} zł
            </Typography>
          )}
        </Box>
        {element.aditionalElements && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {element.aditionalElements.map((subElement) => (
              <Box
                key={subElement.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottom: "1px solid black",
                  width: { xs: "60%", md: "40%" },
                  marginTop: "10px",
                }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "1rem", md: "2rem" },
                    fontWeight: "300",
                  }}>
                  {subElement.aditionalName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "1rem", md: "2rem" },
                    fontWeight: "300",
                  }}>
                  {subElement.aditionalPrice} zł
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    ));
  };

  const Load = () => {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "grey",
          overflow: "hiden",
          zIndex: "1000",
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography variant="h4">Ładowanie danych</Typography>
        <Box sx={{ display: "flex", marginTop: "50px" }}>
          <CircularProgress color="black" />
        </Box>
      </Box>
    );
  };

  return (
    <>
      {load ? (
        <Load />
      ) : (
        <Box className="tariff" sx={{ padding: "100px 0" }}>
          <Box className="tariff_title">
            <p>cennik</p>
          </Box>
          <Box className="Tarif_table">
            <Teriffcolumn />
          </Box>
        </Box>
      )}
    </>
  );
};
