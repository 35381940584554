import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography, CircularProgress } from "@mui/material";
import axios from "axios";
import { useAuthContext } from "../Context/AuthContext";

import "../../styles/events.css";

const Events = () => {
  const [load, setLoad] = useState(true);
  const [eventsList, setEventsList] = useState([]);
  const { serverUrl } = useAuthContext();

  React.useEffect(() => {
    axios
      .get(`${serverUrl}/api/eventAll`)
      .then((res) => {
        setEventsList(res.data);
        setLoad(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, [setEventsList]);

  const Load = () => {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "grey",
          overflow: "hiden",
          zIndex: "1000",
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography variant="h4">Ładowanie danych</Typography>
        <Box sx={{ display: "flex", marginTop: "50px" }}>
          <CircularProgress color="black" />
        </Box>
      </Box>
    );
  };

  return (
    <>
      {load ? (
        <Load />
      ) : (
        <div className="events">
          <div>
            <div className="events_title">
              <p className="title">Najnowsze wydarzenia </p>
              <p className="text">
                Bądz na bierząco z aktualnymi wydarzeniami i nadchodzącymi
              </p>
            </div>
            <div className="acordion">
              {eventsList &&
                eventsList.map((event) => (
                  <Accordion key={event.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header">
                      <Box
                        width="100%"
                        pr={20}
                        pl={5}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center">
                        <Box>
                          <Box fontSize={40}>{event.title}</Box>
                          <p className="time">
                            {event.timeFrom && event.timeTo
                              ? event.timeFrom + " - " + event.timeTo
                              : ""}
                          </p>
                        </Box>
                        <Box display="flex">
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={1}
                            fontSize={25}
                            mr={2}>
                            <Box>{event.month}</Box>
                            <Box>{event.day}</Box>
                          </Box>
                          <Box>
                            <Box fontSize={80}>{event.dayNr}</Box>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={5} fontSize={16}>
                        {event.description}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Events;
