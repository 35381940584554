import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { Box, Typography, CircularProgress } from "@mui/material";

import Schedule from "./SubRoutes/schedule";
import ScheduleResponsive from "./SubRoutes/schedule_responsive";
import { useAuthContext } from "../Context/AuthContext";

import "../../styles/time_table.css";

function TimeTable(props) {
  const { handleDelete, classesData, hours, setclassesData, daysOfWeek } =
    props;
  const [response, setResponse] = useState();
  const [locationPath, setLocationPath] = useState();
  const location = useLocation();
  const [load, setLoad] = useState(true);
  const { serverUrl } = useAuthContext();

  const prevClassesData = useRef(classesData);

  useEffect(() => {
    setLocationPath(location.pathname);
  }, [location]);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await fetch(`${serverUrl}/api/getAll`);
        if (!response.ok) {
          throw new Error("Błąd w odpowiedzi sieciowej");
        }
        const data = await response.json();
        setLoad(false);

        if (!_.isEqual(prevClassesData.current, data)) {
          setclassesData(data);
          prevClassesData.current = data;
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchClasses();
  }, [classesData, setclassesData]);

  const Load = () => {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "grey",
          overflow: "hiden",
          zIndex: "1000",
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography variant="h4">Ładowanie danych</Typography>
        <Box sx={{ display: "flex", marginTop: "50px" }}>
          <CircularProgress color="black" />
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    let size = window.innerWidth;
    if (size < 1024) {
      setResponse(true);
    } else if (size >= 1024) {
      setResponse(false);
    }
    window.addEventListener("resize", () => {
      let size = window.innerWidth;
      if (size < 1024) {
        setResponse(true);
      } else if (size >= 1024) {
        setResponse(false);
      }
    });
  }, []);
  return (
    <>
      {load ? (
        <Load />
      ) : (
        <div className="timeTable">
          <p
            className="title"
            style={{
              display: locationPath === "/configpanel" ? "none" : "block",
            }}>
            Grafik zajęć
          </p>
          {response ? (
            <ScheduleResponsive
              data={classesData}
              handleDelete={handleDelete}
              locationPath={locationPath}
              daysOfWeek={daysOfWeek}
            />
          ) : (
            <Schedule
              hours={hours}
              data={classesData}
              handleDelete={handleDelete}
              locationPath={locationPath}
              daysOfWeek={daysOfWeek}
            />
          )}
        </div>
      )}
    </>
  );
}

export default TimeTable;
