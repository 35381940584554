import { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useAuthContext } from "../../Context/AuthContext";

const schema = yup.object().shape({
  name: yup.string().required("Nazwa produktu jest wymagana"),
  description: yup.string().required("Opis produktu jest wymagany"),
  price: yup
    .number()
    .required("Cena produktu jest wymagana")
    .min(1, "Cena musi być większa lub równa 1"),
  promotion: yup.boolean().default(false),
  promotionPrice: yup
    .number()
    .nullable()
    .test(
      "isPromotionValid",
      "Błąd walidacji promotionPrice",
      function (value) {
        const { promotion } = this.parent;
        if (promotion && (!value || value < 1)) {
          return this.createError({
            message: "Cena promocyjna jest wymagana i musi być większa niż 0",
          });
        }
        return true;
      }
    ),
  image: yup
    .mixed()
    .required("Zdjęcie jest wymagane")
    .test(
      "isBase64OrFile",
      "Dozwolone są tylko pliki w formacie .jpeg, .jpg, .png lub Base64",
      (value) => {
        if (typeof value === "string") {
          const base64Regex = /^data:image\/(jpeg|jpg|png);base64,/;
          return base64Regex.test(value);
        }

        return (
          value &&
          value.type &&
          ["image/jpeg", "image/jpg", "image/png"].includes(value.type)
        );
      }
    )
    .test(
      "fileSizeOrBase64Size",
      "Rozmiar pliku nie może przekraczać 0.60 MB",
      (value) => {
        if (typeof value === "string") {
          const base64Size = (value.length * 3) / 4 - (value.indexOf(",") + 1);
          return base64Size <= 65000;
        }

        return value && value.size <= 65000;
      }
    ),
});

export const ConfigEditShop = () => {
  const [productsFetch, setProductsFetch] = useState([]);
  const { serverUrl } = useAuthContext();
  const [imagePreview, setImagePreview] = useState(null);
  const [selected, setSelected] = useState(null);

  const handleChange = (event) => {
    const selectedProduct = productsFetch.find(
      (product) => product._id === event.target.value
    );
    setSelected(selectedProduct);
  };

  const isBase64 = (str) => {
    const base64Regex = /^data:image\/(jpeg|jpg|png);base64,/;
    return base64Regex.test(str);
  };

  const convertToBase64 = (fileOrBase64) => {
    return new Promise((resolve, reject) => {
      if (typeof fileOrBase64 === "string" && isBase64(fileOrBase64)) {
        resolve(fileOrBase64);
        return;
      }
      if (fileOrBase64 instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(fileOrBase64);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } else {
        reject(new Error("Nieprawidłowy format pliku lub Base64"));
      }
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      name: "",
      description: "",
      price: 0,
      promotion: false,
      promotionPrice: null,
      image: "",
    },
  });

  useEffect(() => {
    if (selected) {
      setValue("name", selected.name);
      setValue("description", selected.description);
      setValue("price", selected.price);
      setValue("promotion", selected.promotion);
      setValue("promotionPrice", selected.promotionPrice || null);
      setValue("image", selected.image || null);
      setImagePreview(selected.image);
    }
  }, [selected, setValue]);
  const promotion = watch("promotion");

  useEffect(() => {
    const promotion = watch("promotion");
    if (!promotion) {
      setValue("promotionPrice", null, { shouldValidate: true });
    }
  }, [promotion, setValue, watch]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setValue("image", file, { shouldValidate: true });
    }
  };

  const productDelete = () => {
    if (!selected) {
      console.error("Nie wybrano produktu do usunięcia");
      return;
    }

    axios
      .delete(`${serverUrl}/api/shop/delete/${selected._id}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        axios
          .get(`${serverUrl}/api/shop`)
          .then((res) => {
            setProductsFetch(res.data);
            setSelected(null);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const productEdit = async (data) => {
    const file = data.image;
    const base64 = await convertToBase64(file);

    axios
      .put(
        `${serverUrl}/api/shop/edit/${selected._id}`,
        { ...data, image: base64 },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        axios
          .get(`${serverUrl}/api/shop`)
          .then((res) => {
            setProductsFetch(res.data);
            setSelected(null);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error("Błąd aktualizacji produktu:", err));
  };

  useEffect(() => {
    axios
      .get(`${serverUrl}/api/shop`)
      .then((res) => {
        setProductsFetch(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [serverUrl]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        width: "100%",
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          flexDirection: "column",
          width: "50%",
        }}>
        <FormControl>
          <InputLabel>Wybierz Produkt</InputLabel>
          <Select
            value={selected ? selected._id : ""}
            label="Wybierz Produkt"
            onChange={handleChange}>
            {productsFetch.map((product) => (
              <MenuItem key={product._id} value={product._id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selected && (
          <Box
            component={"form"}
            fullWidth
            onSubmit={handleSubmit(productEdit)}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              marginTop: "20px",
            }}>
            <Typography variant="h6">Edycja</Typography>
            <TextField
              label="Nazwa produktu"
              variant="standard"
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <TextField
              label="Opis produktu"
              variant="standard"
              {...register("description")}
              error={!!errors.description}
              helperText={errors.description?.message}
            />
            <TextField
              label="Cena produktu"
              variant="standard"
              {...register("price")}
              error={!!errors.price}
              helperText={errors.price?.message}
            />
            <FormControl error={!!errors.promotion}>
              <FormControlLabel
                control={
                  <Checkbox {...register("promotion")} color="primary" />
                }
                label="Promocja"
              />
              {errors.promotion && (
                <FormHelperText>{errors.promotion.message}</FormHelperText>
              )}
            </FormControl>
            <TextField
              label="cena promocyjna"
              variant="standard"
              {...register("promotionPrice")}
              disabled={!watch("promotion")}
              error={!!errors.promotionPrice}
              helperText={errors.promotionPrice?.message}
            />
            <Button variant="contained" component="label">
              Wybierz plik
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => {
                  handleImageChange(e);
                  const file = e.target.files[0];
                  if (file) {
                    setValue("image", file, { shouldValidate: true });
                  }
                }}
              />
            </Button>
            {imagePreview && (
              <Box sx={{ marginTop: 2 }}>
                <img
                  src={imagePreview}
                  alt="Podgląd"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </Box>
            )}
            {errors.image && (
              <Typography variant="body2" color="error">
                {errors.image.message}
              </Typography>
            )}
            <Button type="sumbit" variant="contained">
              Edytuj wybrany
            </Button>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            justifyContent: "center",
            marginTop: "20px",
          }}>
          <Button onClick={productDelete} variant="contained">
            Usuń wybrany
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
