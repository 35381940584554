import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { useAuthContext } from "./Context/AuthContext";
import axios from "axios";

export const Shop = () => {
  const { serverUrl } = useAuthContext();
  const [load, setLoad] = useState(true);
  const [productsFetch, setProductsFetch] = useState([]);

  useEffect(() => {
    axios
      .get(`${serverUrl}/api/shop`)
      .then((res) => {
        setProductsFetch(res.data);
        setLoad(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [serverUrl]);

  const Load = () => {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "grey",
          overflow: "hiden",
          zIndex: "1000",
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography variant="h4">Ładowanie danych</Typography>
        <Box sx={{ display: "flex", marginTop: "50px" }}>
          <CircularProgress color="black" />
        </Box>
      </Box>
    );
  };

  return (
    <>
      {load ? (
        <Load />
      ) : (
        <Box sx={{ marginTop: "100px", padding: "50px" }}>
          <Box>
            <Typography variant="h2">Nasz sklepik</Typography>
            <Typography variant="body1">
              Przedstawiamy ofertę która możesz znaleźć w naszej siłowni
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "50px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            {productsFetch.map((prod, index) => (
              <Card
                key={index}
                sx={{
                  maxWidth: 345,
                  margin: "10px",
                  position: "relative",
                  border: prod.promotion ? "2px solid #f50057" : "none",
                }}>
                <CardActionArea>
                  {prod.promotion && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        backgroundColor: "#f50057",
                        color: "#fff",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}>
                      PROMOCJA
                    </Box>
                  )}

                  <img
                    height="140"
                    src={prod.image}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                    alt={prod.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {prod.name}
                    </Typography>
                    {prod.promotion ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}>
                        <Typography
                          variant="h6"
                          sx={{
                            textDecoration: "line-through",
                            color: "text.secondary",
                          }}>
                          Cena: {`${prod.price}zł`}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            color: "#f50057",
                            fontWeight: "bold",
                          }}>
                          Promocyjna cena: {`${prod.promotionPrice}zł`}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography variant="h6">
                        Cena: {`${prod.price}zł`}
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", marginTop: "10px" }}>
                      {prod.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
