import { Box, Typography, Card } from "@mui/material";

const BirthEvent = () => {
  return (
    <div className="birthday">
      <Box
        sx={{
          padding: "20px",
          marginTop: "100px",
          display: "flex",
          flexDirection: "column",
        }}>
        <Typography
          variant={"h2"}
          fontSize={{ xs: "30px", md: "50px" }}
          fontWeight={400}
          marginTop="50px"
          marginBottom={"50px"}>
          Oferta Urodzinowa w Power On Gym
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "center", md: "flex-start" },
            flexDirection: { xs: "column", md: "row" },
            gap: "50px",
            flexWrap: "wrap",
          }}>
          <Card sx={{ padding: "40px", width: { xs: "80%", md: "30%" } }}>
            <Typography
              variant="h4"
              marginTop="10px"
              paddingBottom={"10px"}
              borderBottom={"1px solid black"}
              fontSize={{ xs: "20px", md: "40px" }}>
              Pakiet 1: Do 15 osób <br />
              Cena: 450 zł
            </Typography>
            <Typography
              variant="h5"
              marginTop="20px"
              paddingBottom={"10px"}
              fontSize={{ xs: "15px", md: "30px" }}>
              W skład pakietu wchodzą:
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              1. Dwie godziny animacji z animatorem :
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              marginLeft={"20px"}
              fontSize={{ xs: "15px", md: "20px" }}>
              - tańce
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              marginLeft={"20px"}
              fontSize={{ xs: "15px", md: "20px" }}>
              - tor przeszkód
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              marginLeft={"20px"}
              fontSize={{ xs: "15px", md: "20px" }}>
              - gry i zabawy sportowe
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              2. Zastawa stołowa
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              3. Muzyka
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              4. Trampoliny
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              5. Uroczyste wniesienie tortu
            </Typography>
            <Typography
              variant="h5"
              marginTop="20px"
              paddingBottom={"10px"}
              fontSize={{ xs: "20px", md: "30px" }}>
              Zakres obowiązków rodzica: <br />
              (Przykładowo)
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              Tort/babeczki
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              Owoce
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              Świeczka
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              Słodycze
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              Woda do picia
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              Obuwie zmienne (poinformowanie gosci)
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              strój sportowy
            </Typography>
            <Typography
              variant="h5"
              marginTop="20px"
              paddingBottom={"10px"}
              fontSize={{ xs: "20px", md: "30px" }}>
              Dodatkowo
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              Rodzice otrzymują w cenie kawę i herbatę.
            </Typography>
          </Card>
          <Card sx={{ padding: "40px", width: { xs: "80%", md: "30%" } }}>
            <Typography
              variant="h4"
              marginTop="10px"
              paddingBottom={"10px"}
              borderBottom={"1px solid black"}
              fontSize={{ xs: "20px", md: "40px" }}>
              Pakiet 2: Do 25 osób <br />
              Cena: 650 zł
            </Typography>
            <Typography
              variant="h5"
              marginTop="20px"
              paddingBottom={"10px"}
              fontSize={{ xs: "20px", md: "30px" }}>
              W skład pakietu wchodzą:
            </Typography>
            <Typography
              variant="body1"
              marginTop="10px"
              fontSize={{ xs: "15px", md: "20px" }}>
              W skład pakietu wchodzą wszystkie elementy z Pakietu 1, ale z
              możliwością zaproszenia większej liczby gości.
            </Typography>
          </Card>
        </Box>
      </Box>
    </div>
  );
};

export default BirthEvent;
