import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, ref, object } from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuthContext } from "../Context/AuthContext";

const Register = () => {
  const Navigate = useNavigate();
  const { serverUrl } = useAuthContext();

  const schema = object().shape({
    login: string().required("Login is required"),
    password: string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])/,
        "Hasło musi zawierać dużą literę, cyfrę i znak specjalny"
      ),
    email: string()
      .email("Email nie jest poprawny")
      .required("Email jest wymagany"),
    confirmPassword: string()
      .oneOf([ref("password")], "Hasła muszą być takie same")
      .required("Potwierdzenie hasła jest wymagane"),
  });

  const onSubmit = (data) => {
    axios
      .post(`${serverUrl}/auth/register`, data)
      .then((res) => {
        Navigate("/configpanel");
      })
      .catch((error) => {
        console.error("Error:", error.data);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      login: "",
      password: "",
      email: "",
      confirmPassword: "",
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}>
      <Card sx={{ width: 400 }}>
        <CardContent>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <TextField
              label="Login"
              variant="standard"
              id="login"
              {...register("login")}
              fullWidth
              margin="normal"
              error={!!errors.login}
              helperText={errors.login?.message}
            />
            <TextField
              label="E-mail"
              variant="standard"
              id="email"
              {...register("email")}
              fullWidth
              margin="normal"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <TextField
              id="password"
              {...register("password")}
              label="Hasło"
              variant="standard"
              type="password"
              fullWidth
              margin="normal"
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            <TextField
              id="confirmPassword"
              {...register("confirmPassword")}
              label="Powtórz hasło"
              variant="standard"
              type="password"
              fullWidth
              margin="normal"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
            />
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}>
              <Button size="small" type="submit">
                Zarejestruj się
              </Button>
              <Box>
                masz już konto?
                <Button size="small">Zaloguj się</Button>
              </Box>
            </CardActions>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Register;
