import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Card,
  CardContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Snackbar,
} from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, number, string, array, boolean } from "yup";
import axios from "axios";
import { useAuthContext } from "../../Context/AuthContext";

const schema = object().shape({
  name: string().required("Nazwa jest wymagana"),
  price: number()
    .when("category", {
      is: false,
      then: () =>
        number()
          .min(1, "Cena musi być większa od 0")
          .required("Cena jest wymagana"),
      otherwise: () => number().nullable(),
    })
    .typeError("Cena musi być liczbą"),

  position: number().nullable().typeError("Pozycja musi być liczbą lub null"),

  category: boolean().required("Kategoria jest wymagana"),

  aditionalElements: array().when("category", {
    is: false,
    then: () => array().nullable(),
    otherwise: () =>
      array().of(
        object().shape({
          aditionalName: string().required("Nazwa jest wymagana"),
          aditionalPrice: number()
            .min(1, "Cena musi być większa od 0")
            .required("Cena jest wymagana")
            .typeError("Cena musi być liczbą"),
        })
      ),
  }),
});

const ConfigTariffEdit = () => {
  const { serverUrl } = useAuthContext();
  const [TariffData, setTariffData] = useState([]);
  const [isPriceDisabled, setIsPriceDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: "",
      name: "",
      price: null,
      position: null,
      category: false,
      aditionalElements: [],
    },
  });

  const { fields, replace } = useFieldArray({
    control,
    name: "aditionalElements",
  });

  const selectedTariffId = watch("id");

  useEffect(() => {
    axios
      .get(`${serverUrl}/api/TariffAll`)
      .then((response) => {
        setTariffData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching tariff data:", error);
        setOpenSnackbar(true);
        setErrorMessage("Wystąpił błąd");
      });
  }, [serverUrl]);

  useEffect(() => {
    const selectedTariff = TariffData.find(
      (tariff) => tariff._id === selectedTariffId
    );

    if (selectedTariff) {
      if (selectedTariff.aditionalElements?.length > 0) {
        replace(selectedTariff.aditionalElements);
        selectedTariff.aditionalElements.forEach((el, index) => {
          setValue(
            `aditionalElements[${index}].aditionalName`,
            el.aditionalName || ""
          );
          setValue(
            `aditionalElements[${index}].aditionalPrice`,
            el.aditionalPrice || null
          );
        });
        setIsPriceDisabled(true);
      } else {
        replace([]);
        setIsPriceDisabled(false);
      }
      setValue("name", selectedTariff.name || "");
      setValue("price", selectedTariff.price || null);
      setValue("category", selectedTariff.category || false);
    } else {
      replace([]);
      setIsPriceDisabled(true);
      setValue("name", "");
      setValue("price", null);
      setValue("category", false);
    }
  }, [selectedTariffId, TariffData, replace, setValue]);

  const tariffDelete = () => {
    axios
      .delete(`${serverUrl}/api/tariffDelete/${selectedTariffId}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(() => {
        axios.get(`${serverUrl}/api/TariffAll`).then((res) => {
          setTariffData(res.data);
        });
      })
      .catch((error) => {
        console.error("Error deleting tariff:", error);
        setOpenSnackbar(true);
        setErrorMessage("Wystąpił błąd");
      });
  };

  const onSubmit = (data) => {
    const confirmed = window.confirm("Zmienić cenę?");
    if (!confirmed) {
      return;
    }

    axios
      .put(`${serverUrl}/api/tariffChange/${data.id}`, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(() => {
        axios
          .get(`${serverUrl}/api/TariffAll`)
          .then((res) => setTariffData(res.data));
      })
      .catch((error) => {
        console.error("Error updating tariff:", error);
        setOpenSnackbar(true);
        setErrorMessage("Wystąpił błąd");
      });
  };

  return (
    <Box>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CardContent>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Nazwa</InputLabel>
              <Select
                {...register("id")}
                value={selectedTariffId || ""}
                label="Nazwa"
                onChange={(e) => {
                  setValue("id", e.target.value);
                }}
                error={!!errors.id}>
                {TariffData.map((tariff) => (
                  <MenuItem key={tariff._id} value={tariff._id}>
                    {tariff.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.id && <p style={{ color: "red" }}>{errors.id.message}</p>}
            </FormControl>

            <TextField
              fullWidth
              label="Nowa nazwa"
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <TextField
              fullWidth
              label="Nowa cena"
              type="number"
              sx={{ marginTop: "20px" }}
              disabled={isPriceDisabled}
              {...register("price")}
              error={!!errors.price}
              helperText={errors.price?.message}
            />

            <TextField
              fullWidth
              label="Pozycja (opcjonalnie)"
              type="number"
              sx={{ marginTop: "20px" }}
              {...register("position")}
              error={!!errors.position}
              helperText={errors.position?.message}
            />

            {fields.map((field, index) => (
              <Box
                key={field.id}
                sx={{ display: "flex", gap: 2, marginTop: 2 }}>
                <TextField
                  fullWidth
                  label="Nazwa"
                  {...register(`aditionalElements[${index}].aditionalName`)}
                  defaultValue={field.aditionalName || ""}
                  error={!!errors.aditionalElements?.[index]?.aditionalName}
                  helperText={
                    errors.aditionalElements?.[index]?.aditionalName?.message
                  }
                />
                <TextField
                  fullWidth
                  label="Cena"
                  type="number"
                  {...register(`aditionalElements[${index}].aditionalPrice`)}
                  defaultValue={field.aditionalPrice || ""}
                  error={!!errors.aditionalElements?.[index]?.aditionalPrice}
                  helperText={
                    errors.aditionalElements?.[index]?.aditionalPrice?.message
                  }
                />
              </Box>
            ))}
          </CardContent>

          <Button
            size="small"
            type="submit"
            variant="contained"
            color="primary">
            Zmień taryfę
          </Button>

          <Button
            variant="contained"
            sx={{ margin: "10px 0" }}
            onClick={() => tariffDelete()}>
            Usuń wybrany
          </Button>
        </Card>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ConfigTariffEdit;
